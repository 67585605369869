import NotFound from "@/components/pages/NotFound";

import { useParams } from "react-router-dom";
import { isUUIDv4 } from "@/utils";
import { useDataContext } from "@/context/DataContext";
import { useEffect, useRef, useState } from "react";
import Http from "@/services/Http/Http.service";

import "./OrderTrack.css";
import { useOrderContext } from "@/context/OrderContext/OrderContext";
import Loader from "@/components/common/Loader";

import OrderTrackHeader from "./OrderTrackHeader";
import OrderTrackStatuses from "./OrderTrackStatuses";
import OrderTrackPaymentInfo from "./OrderTrackPaymentInfo";
import OrderTrackItems from "./OrderTrackItems";
import OrderModal from "./OrderModal";
import OrderTrackPayments from "./OrderTrackPayments";
import OrderUpSale from "./OrderUpSale";
import fakeObject from "./fakeObject";
import OrderPayment from "@/components/pages/OrderTrack/OrderPayment";
export default function OrderTrack() {
  const [isNotFound, setIsNotFound] = useState(false);
  const { uuid } = useParams();
  const {
    data: { brand },
  } = useDataContext();

  const {
    order: [order, setOrder],
    isVerif: [isVerif, setIsVerif],
  } = useOrderContext();
  const getOrder = () => {
    const url = `/get-order.php?uuid=${uuid}`;

    Http.get(url).then((response) => {
      const newOrder = response.data;
      if (response.status === 200 && newOrder?.uuid) {
        setOrder(newOrder);
      }
      if (response.status !== 200) {
        setIsNotFound(true);
      }
    });
  };

  const isUUID4Valid = isUUIDv4(uuid);
  // eslint-disable-next-line
  useEffect(() => {
    isUUID4Valid && getOrder();
    // eslint-disable-next-line
  }, [isUUID4Valid]);

  const renderOrder = useRef();
  renderOrder.current = !isVerif?.[uuid] ? fakeObject : order;

  if (!isUUID4Valid || isNotFound) {
    return <NotFound />;
  }
  if (!order) {
    return <Loader />;
  }

  return (
    <>
      <OrderPayment />
      {!isVerif?.[uuid] && (
        <OrderModal
          isVerif={!isVerif?.[uuid]}
          setIsVerif={setIsVerif}
          phone={order?.lead?.phone_number}
          uuid={uuid}
        />
      )}
      {renderOrder.current?.up_sales && (
        <OrderUpSale up_sales={renderOrder.current?.up_sales} />
      )}

      <OrderTrackHeader
        brand={brand}
        uuid={uuid}
        orderDates={{
          created: renderOrder.current?.created_at,
          to: renderOrder.current?.expired_at,
        }}
        status={renderOrder.current?.status}
      />

      <OrderTrackStatuses />
      <OrderTrackPaymentInfo lead={renderOrder.current?.lead} />

      <OrderTrackItems
        items={renderOrder.current?.items}
        total_price={renderOrder.current?.total_price}
        total_price_with_discount={
          renderOrder.current?.total_price_with_discount
        }
        total_price_with_vat={renderOrder.current?.total_price_with_vat}
        difference={renderOrder.current?.difference}
        vat={renderOrder.current?.vat}
      />
      {renderOrder.current?.payments?.length !== 0 && (
        <OrderTrackPayments payments={renderOrder.current?.payments} />
      )}
    </>
  );
}
